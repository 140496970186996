







import { defineComponent } from '@vue/composition-api';

import TheModal from '@/pages/StressMapPage/components/TheModal';

export default defineComponent({
  name: 'TheMainLayout',
  components: {
    TheModal,
  },
});
