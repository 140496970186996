import { LayerType } from '@/types/data';

type Overview = {
  mapLayers: {
    all: { id: LayerType; url: string }[];
    selected: LayerType;
  };
  center: {
    long: number;
    lat: number;
  };
  zoom: number;
};

export default function overview(config: { long: number; lat: number; zoom: number; layer: LayerType }) {
  const model: Overview = {
    mapLayers: {
      all: [
        {
          id: 'Grayscale',
          // TODO: choose the layer
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
          // url:
          //   'https://tile.jawg.io/jawg-light/{z}/{x}/{y}.png?access-token=urD1ClOU1XOMp4eUM1NhwD0kFXbV23TY6MitwLxRYyb4NcdWKxDgHJV4QouaARD6',
        },
        {
          id: 'Satellite',
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        },
      ],
      selected: config.layer,
    },
    center: {
      long: config.long,
      lat: config.lat,
    },
    zoom: config.zoom,
  };

  const update = (nextState: Omit<Overview, 'mapLayers'>) => {
    model.zoom = nextState.zoom;
    model.center.long = nextState.center.long;
    model.center.lat = nextState.center.lat;
  };

  const selectMapLayer = (nextLayer: LayerType) => {
    model.mapLayers.selected = nextLayer;
  };

  return {
    model: {
      overview: model,
    },
    actions: {
      overview: {
        update,
        selectMapLayer,
      },
    },
  };
}
