import Vue, { Component, CreateElement } from 'vue';
import vCompositionAPI, { createApp, provide, App } from '@vue/composition-api';
import vTooltip from 'v-tooltip';

// Global styles
import 'tailwindcss/tailwind.css';
import 'animate.css';

import './base.css';
import './tooltip.css';
import './inputs.css';
import 'ol/ol.css';
import './ol.css';
import './slider.css';

Vue.use(vTooltip);
Vue.use(vCompositionAPI);

export default function setup(PageComponent: Component): App {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const TheMainLayout = require('../layouts/TheMainLayout').default;
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const store = require('../store');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-var-requires
  const effects = require('../store/effects');

  const app = createApp({
    setup() {
      provide(store.StoreKey, store.default);
    },
    render: (h: CreateElement) => h(TheMainLayout, [h(PageComponent)]),
  });

  app.mount('#app');

  return app;
}
