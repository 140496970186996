import { ref, Ref, onBeforeUnmount } from '@vue/composition-api';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export function createHttpObservable(url: string, init: RequestInit = {}) {
  return new Observable((observer) => {
    const controller = new AbortController();
    const initOptions: RequestInit = {
      signal: controller.signal,
      ...init,
    };

    fetch(url, initOptions)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return observer.error(res);
        }
      })
      .then((body) => {
        observer.next(body);
        observer.complete();
      })
      .catch((err) => {
        observer.error(err);
      });

    return () => {
      controller.abort();
    };
  });
}

export function useObservable<T>(observable: Observable<T>, defaultValue?: T): Ref<T> {
  const handler = ref(defaultValue) as Ref<T>;
  subscribeTo(
    observable,
    (value) => {
      handler.value = value;
    },
    (error) => {
      throw error;
    },
  );

  return handler;
}

export function useSubscription<T>(
  observable: Observable<T>,
  next?: (value: T) => void,
  error?: (err: any) => void,
  complete?: () => void,
) {
  return subscribeTo(observable, next, error, complete);
}

export function useDOMEvent() {
  const subject = new Subject();
  return {
    subject,
    callback: (event: Event) => {
      subject.next(event);
    },
  };
}

function subscribeTo<T>(
  observable: Observable<T>,
  next?: (value: T) => void,
  error?: (err: any) => void,
  complete?: () => void,
) {
  const unsubscribe$ = new Subject<void>();
  const subscription = observable.pipe(takeUntil(unsubscribe$)).subscribe(next, error, complete);
  onBeforeUnmount(() => {
    unsubscribe$.next();
    unsubscribe$.complete();
  });

  return subscription;
}
