import { watch } from '@vue/composition-api';
import store from '@/store';

// TODO: find a better solution. We need this to distinguish changes from initial and further changes
let isInitialized = false;

// When new file is loaded, values range changes, so sync it according to new values and filter from URL
watch(store.model.geotiff, (nextGeotiff) => {
  if (nextGeotiff.valuesRange) {
    let nextValuesRange: [number, number] = [...nextGeotiff.valuesRange];
    if (store.init.share.filter && !isInitialized) {
      const maybeRange = store.init.share.filter;
      if (maybeRange[0] <= maybeRange[1]) {
        if (maybeRange[0] >= nextValuesRange[0] && maybeRange[1] <= nextValuesRange[1]) {
          nextValuesRange = [...maybeRange];
        }
      }
    }
    store.actions.chart.updateRangeFilter(nextValuesRange);
    isInitialized = true;
  }
});

watch(store.model.chart.piecewise.validated.filter, (nextFilter) => {
  if (nextFilter.range) {
    // console.log('update filter values', selectedFileModel.value.file?.scaling.factor);
    // TODO: Duplication with useFileSource projection
    const file = store.model.sources.all.find((el) => el.id === store.model.sources.selected[0]);
    const scaleFactor = file?.scaling?.factor || 1;
    const scaledValues: [number, number] = [nextFilter.range[0] / scaleFactor, nextFilter.range[1] / scaleFactor];
    store.actions.chart.updateRawRangeFilter(scaledValues);
  }
});

watch(store.model.chart.piecewise.validated.count, (nextCount) => {
  if (nextCount.current) {
    store.actions.chart.updateRawPiecesCount(nextCount.current);
  }
});

// TODO: find a better way
// Update data hash to control redraw
watch(
  () => [
    store.model.chart.opacity,
    store.model.chart.piecewise.validated.count.current,
    store.model.chart.piecewise.palette.current,
    store.model.geotiff.valuesRange,
  ],
  () => {
    store.actions.chart.updateDataHash();
  },
);

watch(
  () => store.model.chart.piecewise.validated.filter.range,
  (curr, prev) => {
    if (prev && curr && JSON.stringify(curr) !== JSON.stringify(prev)) {
      store.actions.chart.updateDataHash();
    }
  },
);
