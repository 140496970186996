import { ref, computed } from '@vue/composition-api';

type ModalContent = {
  title: string;
  text: string;
};

const getDefaultContent = () => ({
  title: 'Failed to load data',
  text: 'Data for the current area is missing. Please try selecting another map overview',
});

const state = ref<{
  show: boolean;
  type: 'warning';
  content: ModalContent;
}>({
  show: false,
  type: 'warning',
  content: getDefaultContent(),
});

export default function useTheModal() {
  return {
    show: computed(() => state.value.show),
    title: computed(() => state.value.content.title),
    text: computed(() => state.value.content.text),
    open: (content?: ModalContent) => {
      state.value.show = true;
      state.value.content = content || getDefaultContent();
    },
    close: () => {
      state.value.show = false;
    },
  };
}
