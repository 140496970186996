export const GRID_CELL_NO_VALUE = -9999;

export const MAP_ZOOM_LIMITS = [28, 28 + 9 * 10];

export const MIN_ZOOM_TO_SHOW_CELLS = 91;

export const DEFAULT_CELL_SIZE_KM = 5;

export const PALETTES = ['Grass', 'Wave', 'Cinematic'] as const;

export const PIECES_COUNT_RANGE = [1, 10] as [number, number];

// URLS
export const HOME_URL = '/';
export const MAP_URL = '/map';
