import { of, Subject } from 'rxjs';
import { catchError, debounceTime, distinct, filter, map, switchMap, tap } from 'rxjs/operators';

import { getAttributeHistory } from '@/api';
import { valueIsNotNullOrUndefined } from '@/utils/types';

type AreaData = {
  index: number;
  long: number;
  lat: number;
  val: number;
};

export type AreaHistoryData = {
  label: string;
  values: (number | null)[];
  dates: string[];
};

type Area = {
  data: AreaData | null;
  history: { [key: string]: AreaHistoryData };
};

export const createHistoryKey = (long: number, lat: number, url: string) => `${long}-${lat}-${url}`;

export default function area() {
  const model: Area = {
    data: null,
    history: {},
  };

  const longLatData$ = new Subject<{ long: number; lat: number; historyURL: string }>();

  longLatData$
    .pipe(
      debounceTime(400),
      distinct((val) => `${val.long}${val.lat}`),
      switchMap(({ long, lat, historyURL }) => {
        if (!historyURL) {
          return of(null);
        }
        const d = new Date();
        d.setDate(d.getDate() - 1);
        const longLatKey = createHistoryKey(long, lat, historyURL);
        if (model.history[longLatKey]) {
          return of(null);
        }
        const lastDateAvailable = d.toISOString().split('T')[0];
        return getAttributeHistory(historyURL, long, lat, lastDateAvailable).pipe(
          map((response) => ({ response, historyURL })),
        );
      }),
      catchError(() => {
        return of(null);
      }),
      filter(valueIsNotNullOrUndefined),
      map(({ response, historyURL }) => {
        const dates = Object.keys(response.smapMeasurements).map(String).sort();
        const values = dates.map((el) => response.smapMeasurements[el]);
        return {
          history: {
            label: 'Soil Moisture',
            dates,
            values,
          },
          long: response.lng,
          lat: response.lat,
          historyURL,
        };
      }),

      tap((val) => {
        const historyKey = createHistoryKey(val.long, val.lat, val.historyURL);
        model.history = { ...model.history, [historyKey]: val.history };
      }),
    )
    .subscribe();

  const update = (area: AreaData) => {
    model.data = area;
  };

  const updatePart = (data: Partial<AreaData>) => {
    if (model.data) {
      model.data = { ...model.data, ...data };
    }
  };

  const updateHistory = (long: number, lat: number, historyURL?: string) => {
    if (historyURL) {
      longLatData$.next({ long, lat, historyURL });
    }
  };

  return {
    model: {
      area: model,
    },
    actions: {
      area: {
        update,
        updatePart,
        updateHistory,
      },
    },
  };
}
