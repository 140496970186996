








































































import { defineComponent } from '@vue/composition-api';
import useTheModal from './useTheModal';

export default defineComponent({
  name: 'TheModal',
  setup() {
    const { show, title, text, close } = useTheModal();
    return { show, title, text, close };
  },
});
